<template>
  <svg viewBox="0 0 496.158 496.158">
    <path fill="#e61700" d="M0,248.085C0,111.063,111.069,0.003,248.075,0.003c137.013,0,248.083,111.061,248.083,248.082
      c0,137.002-111.07,248.07-248.083,248.07C111.069,496.155,0,385.087,0,248.085z"/>
    <polygon fill="#FFFFFF" points="248.071,89.962 296.868,188.781 405.929,204.648 326.993,281.565 345.626,390.198 
      248.071,338.91 150.505,390.198 169.143,281.565 90.229,204.648 199.305,188.781 "/>
  </svg>
</template>

<script>
  export default {
    name: 'star',
  }
</script>
