<template>
  <div>
      <div class='container'>
        
        <div :class="['single-article', listing.type]">

          <div class='row article-content-wrapper'>
            <div class='col-md-8 col-sm-12'>
              <div class="row">
                <div class="col-md-12">
                  <a class="go-back" @click="$router.go(-1)">&lt; Go back</a>
                </div>
              </div>
              <div v-if="sponsored" class='row article-head'>
                
                <div v-if="listing.video" class="col-md-12">
                  <!-- <div class="breadcrumbs"><a :href="'/search?section=' + listing.section">{{ listing.section }}</a> > {{ listing.title }}</div> -->
                  <div v-if="listing.video.indexOf('amazonaws') !== -1" class="enhanced-image">
                    <img :src="listing.video" style="width:100%;" alt="">
                  </div>
                  
                  <randomo v-else-if="listing.video" :video-id="listing.video" :title="listing.title"></randomo>
                  <div v-else-if="listing.image" class='hit-image'>
                    <div class='image'>
                      <!-- <img v-bind:src="enhanced_image" /> -->
                    </div>
                  </div>
                  
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  
                  <div class="dir-block">
                    <div v-if="sponsored" class="sponsored">
                      Sponsored
                    </div>
                    <div class="listing-location">
                      <h1 class="dir-title" v-html="listing.title"></h1>
                      
                      <!-- Category -->
                      <div v-if="cleanCategories" class='dir-categories dir-block__categories'>
                        
                        <h3 class='dir-block__categories-title'>{{ cleanCategories.length > 1 ? 'Categories': 'Category' }}:&nbsp;</h3>
                          <span v-for="(category, index) in cleanCategories" :key="index">
                            {{ category }}{{ index + 1 === cleanCategories.length ? '' : ','}}
                          </span>
                      </div>
                    
                      <p v-if="listing.company">
                        {{ listing.company }}
                      </p>
                      <p v-if="listing.address">
                        <span>{{ listing.address }}<br/>{{ listing.city }}, {{ listing.state }} {{ listing.zip }}</span><br />
                        <a  v-on:click="clicked('Listing Directions Clicked', listing.title)" target="_blank" :href="mapUrl">Get Directions</a>
                      </p>
                    </div>
                    <div v-if="listing.additional_locations">
                     <p> <strong>Additional Locations:</strong> {{listing.additional_locations | remove_pipe}} </p>
                    </div>
                    <p>
                      Phone: <a v-on:click="clicked('Listing Phone Clicked', listing.title)" :href="phoneLink">{{ listing.phone }}</a>
                    </p>
                    <p class="dir-block_item website">
                      Website: <a class="website" v-on:click="clicked('Listing Website Clicked', listing.title)" :href="listing.website" target="_blank">{{listing.website}}</a>
                    </p>
                  
                  </div>

                  <div v-if="sponsored">
                      <h3 class="profile-title">Profile</h3>
                      <div v-if="listing.content" class="profile-content" v-html="listing.content"></div>
                  </div>

                  <a v-if="listing.review_link" class="button-3" target="_blank" :href="listing.review_link">Read Our Take</a>

                </div>

              </div>
            </div>

            <div class='col-md-4 sidebar'>
              <div class='dir-blocks-main'>
                <div class='dir-blocks dir-blocks--1'>
                  <div>
                    
                    <no-ssr>
                      <div class="leaflet-map-container">
                        <simple-map :listing="listing"></simple-map>
                        <a  v-on:click="clicked('Listing Directions Clicked', listing.title)" target="_blank" :href="mapUrl">Directions</a>
                      </div>
                    </no-ssr>

                    <!-- Awards -->
                    <div v-if="awards" class='dir-categories dir-block__categories'>
                      <h3 class='dir-block__categories-title'>Awards:&nbsp;</h3>
                      <span v-for="(award, index) in awards" :key="index">
                        {{ award | seperate_years }}{{ index + 1 === awards.length ? '' : ','}}
  
                       
                      </span>
                    </div>

                    <!-- {{ listing.star_rating[0].name }} -->

                    <!-- Rating -->
                    <div v-if="listing.star_rating" class="dir-categories dir-block__categories rating">
                      <h3 class='dir-block__categories-title'>
                        Rating:</h3> <stars :rating="listing.star_rating[0].name"></stars>
                    </div>

                    <!-- Price Range -->
                    <div v-if="listing.price_range" class='dir-categories dir-block__categories'>
                        <h3 class='dir-block__categories-title'>Price:&nbsp;</h3>
                        {{ listing.price_range[0].name }}
                    </div>

                    <!-- Hours -->
                    <div v-if="listing.hours" class='dir-categories dir-block__categories'>
                        <h3 class='dir-block__categories-title'>Hours: </h3>
                        <div class="hours-content" v-html="listing.hours"></div>
                    </div>

                  </div>
                </div>
                <div class='dir-blocks dir-blocks--1'>
                    <div id="broadstreet-in-list">
                      <broadstreet-zone zone-id="66356"></broadstreet-zone>
                    </div>
                </div>
              </div>
            </div>

          </div>
        
        </div>

      </div>
  </div>
</template>

<script>

import SimpleMap from 'components/SimpleMap.vue';
import NoSSR from 'vue-no-ssr';
import Stars from 'components/Stars.vue';
// import {YoutubePlayer} from 'components/YouTube.js';
import Randomo from 'components/Randomo.vue';
import {S3_URL} from '../environment.js';


export default {
  name: 'listing-view',

  components: {
    'simple-map': SimpleMap,
    'no-ssr': NoSSR,
    Stars,
    Randomo
  },
  data () {
    console.log('current page', this.$route.query['page'])
    return {
      mapUrl: '',

    }


  },


  // beforeCreate() {
  // 	this.$store.dispatch('FETCH_CURRENTLY_SELECTED_FACETS', {});
  // },


  computed: {
		image() {
      // return this.listing.image && this.listing.ad_level !== 0;
      return S3_URL + '/' + this.listing.id + '/featured/' + this.listing.image;
		},
    listing () {
      return this.$store.state.listing
    },
    sponsored() {
      return this.listing.ad_level > 0;
    },
    phoneLink() {
      return 'tel:' + this.listing.phone;
    },


    // awards() {
    //   return this.listing.awards && this.listing.awards.count > 0;
    // },
    // cleanCategories() {
    //   let categories = '';
    //   if (this.listing.hcategory) {
    //     if (this.listing.hcategory['lvl2']) {
    //       categories = this.listing.hcategory['lvl2'];
    //     } else if (this.listing.hcategory['lvl1']) {
    //       categories = this.listing.hcategory['lvl1'];
    //     } else if (this.listing.hcategory['lvl0']) {
    //       categories = this.listing.hcategory['lvl0'];
    //     }

    //     let cleanCategories = [];
    //     categories.map(function(category) {
    //       var parts = category.split(' > ');
    //       var cleanCategory = parts[parts.length - 1];
    //       cleanCategories.push(cleanCategory);
    //     })
  
    //     return cleanCategories;
    //   }

    //   return false;

    // },
    cleanCategories() {
var cleanCategories = [];
  
  if (this.listing.hcategory) {
    this.listing.hcategory.map(function(cat0) {
      cat0.has_children ? null : cleanCategories.push(cat0.name);
      
      if (cat0.has_children) {
        cat0.children.map(function(cat1) {
          cat1.has_children ? null : cleanCategories.push(cat1.name);
        
          if (cat1.has_children) {
            console.dir(cat1.children)
            cat1.children.map(function(cat2) {
              cleanCategories.push(cat2.name);
            });
          }
        })
      }
    })
    return cleanCategories;
  }


      // if (this.listing.hcategory) {
        
      //   if (this.listing.hcategory['lvl2']) {
      //     categories = this.listing.hcategory['lvl2'];
      //   } else if (this.listing.hcategory['lvl1']) {
      //     categories = this.listing.hcategory['lvl1'];
      //   } else if (this.listing.hcategory['lvl0']) {
      //     categories = this.listing.hcategory['lvl0'];
      //   }

      //   let cleanCategories = [];
      //   categories.map(function(category) {
      //     var parts = category.split(' > ');
      //     var cleanCategory = parts[parts.length - 1];
      //     cleanCategories.push(cleanCategory);
      //   })
  
      //   return cleanCategories;
      // }

      return false;


    },
    awards() {
      let cleanAwards = [];
      
      if (this.listing.award) {
      console.log("this.listing.award: ")
      console.dir(this.listing.award);
        this.listing.award.map(function(cat0) {
          
          let title = cat0.name;

          if (cat0.has_children) {
            title += ' (';
            cat0.children.map(function(cat1) {
              title += cat1.name;
            })
            title += ')';
          }
          
      
          cleanAwards.push(title);
          

        })

        return cleanAwards;
      }

      return false;
    },
    // awards() {
    //   let hawards = '';
    //   if (this.listing.hcategory) {
    //     if (this.listing.hawards['lvl1']) {
    //       hawards = this.listing.hawards['lvl1'];
    //     } else if (this.listing.hawards['lvl0']) {
    //       hawards = this.listing.hawards['lvl0'];
    //     }
    //     return hawards;
    //   }
    //   return false;

		// 	// console.log('hawards', this.item.hawards.lvl0)
		// 	// if (this.item.hawards !== undefined && this.item.hawards.lvl0 !== undefined && this.item.hawards.lvl0.length > 0) {
		// 	// 	console.log('return awards', this.item.hawards);
		// 	// 	return this.item.hawards.lvl0;
		// 	// }
		// 	// return false;
    // }

  },

  mounted() {

    if (window !== undefined) {

      var broadstreetadsScript = document.createElement('script');
      broadstreetadsScript.setAttribute('src', 'https://cdn.broadstreetads.com/init-2.min.js');
      document.body.appendChild(broadstreetadsScript);
      const options = {
        networkId: 4933
      }
      
      window.onload = function() {
        const JSON_options = JSON.stringify(options);
        broadstreet.watch(options);
      }

      this.$ga.set(this.$route.path);
      this.$ga.page(this.$route.path);

      // ga('set', 'page', this.$route.path);
      // ga('send', 'pageview');

      let mapSite = '';
      let mapUrl = '';
      if (this.iOS()) {
        console.log('you are using iOS')
        mapSite = '//maps.apple.com/?q=';
      } else {
        console.log('not iOS');
        mapSite = '//maps.google.com/?q=';
      }
      mapUrl = mapSite + this.listing.address + ',' + this.listing.city + ',' + this.listing.state;
      this.mapUrl = mapUrl;
    }
    
  },


  asyncData ({ store, route }) {

    // console.log('listing route', route);
    const slug = route.params.id;
    // This should run each time the directory changes. It
    // should be included elsewhere
    // store.dispatch('FETCH_LISTING_METADATA', {});
    // Fetch directory runs whenever the route changes and
    // on first load (here).

    return store.dispatch('FETCH_LISTING', { slug })
    
  },

  serverAsyncData ({ store, route }) {

    // console.log('listing route', route);
    const slug = route.params.id;
    // This should run each time the directory changes. It
    // should be included elsewhere
    // store.dispatch('FETCH_LISTING_METADATA', {});
    // Fetch directory runs whenever the route changes and
    // on first load (here).

    return store.dispatch('FETCH_LISTING', { slug })
    
  },
  metaInfo () {
    return this.$seo('item', this.listing.title)
  },


  methods: {

    clicked: function(action, title) {
      
      if (window !== undefined) {
        console.log('clicked2', action, title, window, 'ga', this.$ga);
        this.$ga.event({
          eventCategory: 'Listing Events',
          eventAction: action,
          eventLabel: title,
        })
        // ga('send', {
        //   hitType: 'event',
        //   eventCategory: 'Listing Events',
        //   eventAction: action,
        //   eventLabel: title
        // });
      }
    },

    iOS: function() {
      if (window !== undefined) {
        var iDevices = [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ];
      
        if (!!navigator.platform) {
          while (iDevices.length) {
            if (navigator.platform === iDevices.pop()){ return true; }
          }
        }
      }

      return false;
    }
  },
  filters: {
      seperate_years : function(value){
        var strarr = value.split("(");
        console.dir(strarr)
        if (strarr[1]){
        var year_to_edit = strarr[1];
        var cut_off_point = strarr[1].length - 1;
        var award_years = year_to_edit.substring(0,cut_off_point);
        var newAwards = ""

        for(var i = 0; i <= cut_off_point - 1; i++  ){
          var seperate = i !== cut_off_point && i % 4 === 0  && i !== 0? ", ":"";
          newAwards += seperate + award_years[i];
        } 

        value = strarr[0] + "(" + newAwards + ")";
        return value;
        }
      },
      remove_pipe:function(value){
        let myvalue = value.replace("|",",");
        return myvalue;
      },

    },


  

  watch: {
    $route (to, from) {
      console.log('route to from', to, from)
      const slug = to.id;
      this.$store.dispatch('FETCH_LISTING', {slug})
    }
  }

}


</script>

<style lang="scss">
@import "~base";

.container {
  font-family: "Open Sans";
}

// .button-3 {
// 	-moz-box-shadow:inset 0px 0px 15px 3px #23395e;
// 	-webkit-box-shadow:inset 0px 0px 15px 3px #23395e;
// 	box-shadow:inset 0px 0px 15px 3px #23395e;
// 	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #2e466e), color-stop(1, #415989));
// 	background:-moz-linear-gradient(top, #2e466e 5%, #415989 100%);
// 	background:-webkit-linear-gradient(top, #2e466e 5%, #415989 100%);
// 	background:-o-linear-gradient(top, #2e466e 5%, #415989 100%);
// 	background:-ms-linear-gradient(top, #2e466e 5%, #415989 100%);
// 	background:linear-gradient(to bottom, #2e466e 5%, #415989 100%);
// 	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e466e', endColorstr='#415989',GradientType=0);
// 	background-color:#2e466e;
// 	-moz-border-radius:10px;
// 	-webkit-border-radius:10px;
// 	border-radius:10px;
// 	border:1px solid #1f2f47;
// 	display:inline-block;
// 	cursor:pointer;
// 	color:#ffffff;
// 	font-family:Arial;
// 	font-size:15px;
// 	padding:6px 13px;
// 	text-decoration:none;
// 	text-shadow:0px 1px 0px #263666;
//   transition:  all .3s ease-in-out;
// }
// .button-3:hover {
// 	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #415989), color-stop(1, #2e466e));
// 	background:-moz-linear-gradient(top, #415989 5%, #2e466e 100%);
// 	background:-webkit-linear-gradient(top, #415989 5%, #2e466e 100%);
// 	background:-o-linear-gradient(top, #415989 5%, #2e466e 100%);
// 	background:-ms-linear-gradient(top, #415989 5%, #2e466e 100%);
// 	background:linear-gradient(to bottom, #415989 5%, #2e466e 100%);
// 	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#415989', endColorstr='#2e466e',GradientType=0);
// 	background-color:#415989;
// }
// .button-3:active {
// 	position:relative;
// 	top:1px;
// }



.button-3 {
background-color: #929292;
border-radius: 4px;
display: inline-block;
cursor: pointer;
color: #ffffff;
font-family: Arial;
font-size: 15px;
padding: 6px 13px;
text-decoration: none;
transition: all .1s ease-in-out;

}
.button-3:hover {
	// background-color:#50ce42;
  background-color: #40a836;
  
  background-color: #36a85a;
}
.button-3:active {
	position:relative;
	top:1px;
}



</style>

